import { forwardRef, ReactElement, ReactNode, Ref } from 'react'

import { TransitionProps } from '@mui/material/transitions'
import { Dialog, Slide } from '@mui/material'

import { useFullDialogContext } from 'contexts/FullDialogContext'

interface ContainerProps {
  hideBackdrop?: boolean
  children: ReactNode
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<any>
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Container(props: ContainerProps) {
  const { hideBackdrop = true, children } = props
  const { isOpen, closeDialog } = useFullDialogContext()

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={closeDialog}
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          height: 'calc(100vh - 48px)',
          marginTop: 'auto',
          overflow: 'hidden',
        },
      }}
      hideBackdrop={hideBackdrop}
    >
      {children}
    </Dialog>
  )
}
