import { ReactNode } from 'react'
import FullDialogProvider from 'contexts/FullDialogContext'
import TokenPermissionKeys from 'interfaces/TokenPermissionKeys'

interface RequestProps {
  api?: TokenPermissionKeys
  route?: string
  key?: string
  getById?: (id: string) => Promise<any>
}

interface RootProps {
  children: ReactNode
  requestConfig?: RequestProps
}

export default function Root(props: RootProps) {
  const { children } = props
  return <FullDialogProvider>{children}</FullDialogProvider>
}
