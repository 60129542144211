import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
} from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

import { Checkbox } from '@data-c/ui'

import { useTagContext } from 'components/Tags'
import Loading from 'components/Displays/Loading'

export default function ListItens() {
  const { data, onChangeTag, isLoading } = useTagContext()
  return (
    <Stack position="relative" height="100%">
      {isLoading ? (
        <Loading customSx={{ position: 'absolute' }} />
      ) : (
        data.map((grupo) => {
          const onlyTagsWithChecked = grupo.tags.some((tag) => tag.isChecked)
          return (
            <Accordion
              key={grupo.id}
              style={{ borderRadius: 0 }}
              defaultExpanded={onlyTagsWithChecked}
              sx={{
                '&.MuiAccordion-root.Mui-expanded': {
                  backgroundColor: 'rgba(0,0,0, 4%)',
                },
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {grupo?.nome || ''}
              </AccordionSummary>
              <AccordionDetails>
                {grupo?.tags?.map((tag) => (
                  <Checkbox
                    key={tag.id}
                    name={tag?.nome}
                    label={tag?.nome || ''}
                    checked={tag?.isChecked || false}
                    onChange={(_, checked) =>
                      onChangeTag({
                        grupoId: grupo.id,
                        tagId: tag.id,
                        isChecked: checked,
                      })
                    }
                  />
                ))}
              </AccordionDetails>
            </Accordion>
          )
        })
      )}
    </Stack>
  )
}
