import { ActionTypes, TagsGrupoProps } from '.'

export interface ReducerStateProps {
  data: TagsGrupoProps[]
  gruposComTagsSelecionadas: TagsGrupoProps[]
  tagsAmount: number
  selectedTagsAmount: number
}

function getTagsAmount(value: TagsGrupoProps[]) {
  return value.reduce((acc, grupo) => acc + grupo.tags.length, 0)
}

export default function Reducer(
  state: ReducerStateProps,
  action: { type: ActionTypes; payload: any },
) {
  switch (action.type) {
    case ActionTypes.SET_DATA_INITIAL: {
      const { initialData, storageId } = action.payload as {
        initialData: TagsGrupoProps[]
        storageId: string
      }

      const savedData = localStorage.getItem(storageId)
      if (savedData) {
        const parsedSavedData: TagsGrupoProps[] = JSON.parse(savedData)
        const updatedData = initialData.map((grupo) => {
          const savedGrupo = parsedSavedData.find((g) => g.id === grupo.id)

          if (savedGrupo) {
            const updatedTags = grupo.tags.map((tag) => {
              const savedTag = savedGrupo.tags.find((t) => t.id === tag.id)
              return savedTag || { ...tag, isChecked: false }
            })
            return { ...grupo, tags: updatedTags }
          } else {
            return grupo
          }
        })

        const gruposComTagsSelecionadas = updatedData
          .map((grupo) => ({
            ...grupo,
            tags: grupo.tags.filter((tag) => tag.isChecked),
          }))
          .filter((grupo) => grupo.tags.length > 0)

        localStorage.setItem(storageId, JSON.stringify(updatedData))

        return {
          ...state,
          data: updatedData,
          gruposComTagsSelecionadas,
          selectedTagsAmount: getTagsAmount(gruposComTagsSelecionadas),
          tagsAmount: getTagsAmount(updatedData),
        }
      }

      return {
        ...state,
        data: initialData.map((grupo) => {
          return {
            ...grupo,
            tags: grupo.tags.map((tag) => ({ ...tag, isChecked: false })),
          }
        }),
        tagsAmount: getTagsAmount(initialData),
      }
    }
    case ActionTypes.CHANGE_TAG: {
      const { grupoId, tagId, isChecked } = action.payload
      return {
        ...state,
        data: state.data.map((grupo) =>
          grupo.id === grupoId
            ? {
                ...grupo,
                tags: grupo.tags.map((tag) =>
                  tag.id === tagId ? { ...tag, isChecked } : tag,
                ),
              }
            : grupo,
        ),
      }
    }
    case ActionTypes.SAVE_TAGS: {
      const { storageId } = action.payload

      const gruposComTagsSelecionadas = state.data
        .map((grupo) => ({
          ...grupo,
          tags: grupo.tags.filter((tag) => tag.isChecked),
        }))
        .filter((grupo) => grupo.tags.length > 0)

      localStorage.setItem(storageId, JSON.stringify(state.data))

      return {
        ...state,
        gruposComTagsSelecionadas,
        selectedTagsAmount: getTagsAmount(gruposComTagsSelecionadas),
      }
    }
    case ActionTypes.RESET_TAGS: {
      const { isChecked } = action.payload
      return {
        ...state,
        data: state.data.map((grupo) => ({
          ...grupo,
          tags: grupo.tags.map((tag) => ({
            ...tag,
            isChecked,
          })),
        })),
      }
    }
    case ActionTypes.SELECT_ALL_TAGS: {
      const { isChecked } = action.payload
      return {
        ...state,
        data: state.data.map((grupo) => ({
          ...grupo,
          tags: grupo.tags.map((tag) => ({
            ...tag,
            isChecked,
          })),
        })),
      }
    }
    default:
      return state
  }
}
