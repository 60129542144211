import { ReactNode } from 'react'
import { Box, BoxProps } from '@mui/material'

interface ContentProps extends BoxProps {
  children: ReactNode
}

export default function Content({ children, ...rest }: ContentProps) {
  return (
    <Box component="main" height="100%" overflow="auto" padding={1.5} {...rest}>
      {children}
    </Box>
  )
}
