import { useQuery } from '@tanstack/react-query'
import { AxiosError, AxiosRequestConfig } from 'axios'

import { apiMapping } from 'hooks/useApi'
import useNotification from 'hooks/useNotification'
import useDownload from 'hooks/useDownload'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import TokenPermissionKeys from 'interfaces/TokenPermissionKeys'

export interface RelatorioModel {
  id: string
  chaveId: string
  nome: string
  systemFlag: boolean
}

export interface ObterRelatorioPorChaveIdProps {
  chaveId: string
  path?: string
  api?: TokenPermissionKeys
}

interface ObterPDFProps {
  route: string
  axiosRequestConfig?: AxiosRequestConfig<any>
  ids?: string[] | (string | undefined)[]
  api?: TokenPermissionKeys
}

export async function obterRelatorioPorChaveId(
  props: ObterRelatorioPorChaveIdProps,
): Promise<HttpResponseInterface<RelatorioModel>> {
  const { chaveId, api = 'stella-faturamento', path } = props

  const route = path ? path : 'ObterConsultaPorChaveId'

  const response = await apiMapping[api].get<
    HttpResponseInterface<RelatorioModel>
  >(`Relatorio/${route}`, {
    params: { chaveId },
  })

  return response.data
}

export async function obterPDF(props: ObterPDFProps) {
  const { route, axiosRequestConfig, ids, api = 'stella-faturamento' } = props
  const { relatorioId } = axiosRequestConfig?.params

  const configs:
    | AxiosRequestConfig<string[] | (string | undefined)[]>
    | undefined = {
    params: axiosRequestConfig?.params,
    headers: {
      'DC-PrintMode': 'PDF',
      'DC-PrintRelatorioId': relatorioId,
    },
    responseType: 'blob',
    ...axiosRequestConfig,
  }

  if (ids && ids?.length > 0) {
    const response = await apiMapping[api].post(route, ids, configs)
    return response.data
  }

  const response = await apiMapping[api].get(route, configs)
  return response.data
}

export function useQueryObterRelatorioPorChaveId(
  props: ObterRelatorioPorChaveIdProps,
) {
  return useQuery<HttpResponseInterface<RelatorioModel>, AxiosError>(
    ['RELATORIO', props],
    () => {
      return obterRelatorioPorChaveId(props)
    },
  )
}

export function useQueryObterPDF(props: ObterPDFProps) {
  return useQuery(
    ['PDF', props],
    () => {
      return obterPDF(props)
    },
    { enabled: false },
  )
}

export function useObterPDF() {
  const { progress, start, stop, isDownloading, updateProgress } = useDownload()
  const notifications = useNotification()

  async function obterPDF(props: ObterPDFProps) {
    const { route, axiosRequestConfig, ids, api = 'stella-faturamento' } = props
    const { relatorioId } = axiosRequestConfig?.params

    const configs:
      | AxiosRequestConfig<string[] | (string | undefined)[]>
      | undefined = {
      params: axiosRequestConfig?.params,
      headers: {
        'DC-PrintMode': 'PDF',
        'DC-PrintRelatorioId': relatorioId,
      },
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        updateProgress(loaded, total || 0)
      },
      ...axiosRequestConfig,
    }

    try {
      start()
      if (ids && ids?.length > 0) {
        const response = await apiMapping[api].post(route, ids, configs)
        return response.data
      }

      const response = await apiMapping[api].get(route, configs)
      return response.data
    } catch (error) {
      notifications.notifyException(error, { position: 'top-right' })
    } finally {
      stop()
    }
  }

  return { obterPDF, progress, isDownloading }
}

export default function usePDF() {
  return {
    useQueryObterRelatorioPorChaveId,
    useQueryObterPDF,
    useObterPDF,
  }
}
