import { ReactNode } from 'react'
import { Stack } from '@mui/material'

interface HeaderProps {
  children: ReactNode
}

export default function Header({ children }: HeaderProps) {
  return (
    <Stack component="header" padding={2} gap={3}>
      {children}
    </Stack>
  )
}
