import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import useNotification from 'hooks/useNotification'

import HttpResponseInterface from 'interfaces/HttpResponseInterface'
import ErrorInterface from 'interfaces/ErrorInterface'

import api from 'services/api'
import { NFModel } from './useNF'

export interface DocumentoReferenciadoModel {
  id?: string
  nfId: string
  tipoDocumento: number
  tipoDocumentoDesc?: string | null
  chaveAcesso?: string | null
  codigoUF?: string | null
  emissao?: string | null
  cnpj?: string | null
  ie?: string | null
  modelo?: string | null
  serie?: string | null
  numeroNF?: number | null
  ecfNumero?: number | null
  ecfcoo?: number | null
  nf?: NFModel | null
}

export interface AlterarIntermediadorModel {
  intermediadorCNPJ: string
  intermediadorIdentificador: string
}

async function adicionarDocumentoReferenciadoNF(
  data: DocumentoReferenciadoModel,
): Promise<HttpResponseInterface<NFModel>> {
  const response = await api.post<HttpResponseInterface<NFModel>>(
    'NF/AdicionarDocumentoReferenciado',
    data,
    { params: { id: data?.nfId } },
  )
  return response.data
}

async function alterarInformacoesComplementares(
  data: NFModel,
): Promise<HttpResponseInterface<NFModel>> {
  const {
    informacoesAdicionais,
    informacoesComplSistema,
    informacoesComplementares,
  } = data

  const response = await api.put<HttpResponseInterface<NFModel>>(
    'NF/AlterarInformacoesComplementares',
    {
      informacoesAdicionais,
      informacoesComplSistema,
      informacoesComplementares,
    },
    { params: { id: data.id } },
  )
  return response.data
}

async function alterarIntermediador(
  nfId: string,
  data: AlterarIntermediadorModel,
) {
  const response = await api.put('NF/AlterarIntermediador', data, {
    params: { id: nfId },
  })
  return response.data
}

export async function removerDocumentoReferenciadoNF(
  data: DocumentoReferenciadoModel,
): Promise<void> {
  const response = await api.delete('NF/RemoverDocumentoReferenciado', {
    params: { id: data.nfId, nfReferenciadoId: data.id },
  })
  return response.data
}

export function useAdicionarDocumentoReferenciadoNF() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    DocumentoReferenciadoModel
  >((data) => adicionarDocumentoReferenciadoNF(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['NF'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarInformacoesComplementares() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    NFModel
  >((data) => alterarInformacoesComplementares(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['NF'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useAlterarIntermediador() {
  const notifications = useNotification()
  const queryClient = useQueryClient()

  return useMutation<
    HttpResponseInterface<NFModel>,
    AxiosError<ErrorInterface>,
    {
      nfId: string
      data: AlterarIntermediadorModel
    }
  >(({ nfId, data }) => alterarIntermediador(nfId, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['NF'])
    },
    onError: (error) => {
      notifications.notifyException(error)
    },
  })
}

export function useRemoverDocumentoReferenciadoNF() {
  const notifications = useNotification()
  const queryClient = useQueryClient()
  return useMutation<void, AxiosError, DocumentoReferenciadoModel>(
    (data) => removerDocumentoReferenciadoNF(data),
    {
      onSuccess() {
        queryClient.invalidateQueries(['NF'])
      },
      onError(error) {
        notifications.notifyException(error)
      },
    },
  )
}

export default function useNFOutrasInformacoes() {
  return {
    useAdicionarDocumentoReferenciadoNF,
    useAlterarInformacoesComplementares,
    useAlterarIntermediador,
    useRemoverDocumentoReferenciadoNF,
  }
}
