import { ReactNode, useCallback, useEffect, useMemo } from 'react'
import { useFilterData, useFilterApi } from '@data-c/providers'

import FlagInfoItem from 'components/Displays/FlagInfos/components/FlagInfoItem'
import FlagInfos from 'components/Displays/FlagInfos'
import checkFilter from 'utils/checkFilters'

interface FilterControlProps<T> {
  initialFilters?: any
  disableds?: Array<string>
  renderLabel?: (filterLabel: string, filterValue: T) => ReactNode
  deleteDynamicFilterValue?: (filterName: string) => void
}

export default function FilterControl<T extends { [key: string]: any }>(
  props: FilterControlProps<T>,
) {
  const { initialFilters, disableds, renderLabel, deleteDynamicFilterValue } =
    props
  const { deleteFilterValue, applyFilterValues } = useFilterApi()

  const { filterValues, appliedValues } = useFilterData<T>()

  useEffect(() => {
    if (!appliedValues && initialFilters) {
      applyFilterValues()
    }
  }, [appliedValues, initialFilters])

  const hasFilter = useMemo((): boolean => {
    const hasFilter1 = Object.keys(appliedValues || {}).length > 0
    if (hasFilter1) {
      let hasFilter2 = false
      const keys = Object.keys(appliedValues || {})
      for (let i = 0; i < keys.length; i++) {
        const filter = appliedValues[keys[i]]
        if (Array.isArray(filter) && filter.length > 0) {
          hasFilter2 = true
          break
        } else if (!Array.isArray(filter) && filter) {
          hasFilter2 = true
          break
        }
      }
      return hasFilter2
    }
    return false
  }, [appliedValues])

  const _renderLabel = useCallback(
    (filterName: keyof T) => {
      if (renderLabel) {
        return renderLabel(
          filterValues[filterName].label,
          appliedValues[filterName],
        )
      }

      return `${filterValues[filterName].label}: ${
        appliedValues && appliedValues[filterName]
      }`
    },
    [renderLabel, filterValues, appliedValues],
  )

  const showFilters = checkFilter(appliedValues)

  return showFilters ? (
    <FlagInfos sx={{ minHeight: '40px' }}>
      {hasFilter &&
        Object.keys(appliedValues || {}).map((filterName: string) => {
          const disabled =
            Array.isArray(disableds) && disableds.includes(filterName)

          let onDelete: ((event: any) => void) | undefined

          if (!disabled) {
            onDelete = () => {
              if (deleteFilterValue) {
                deleteFilterValue(filterName)
                deleteDynamicFilterValue && deleteDynamicFilterValue(filterName)
              }
            }
          }

          if (
            Array.isArray(appliedValues[filterName]) &&
            appliedValues[filterName].length > 0
          ) {
            return (
              <FlagInfoItem
                key={filterName}
                label={_renderLabel(filterName)}
                variant="outlined"
                onDelete={onDelete}
              />
            )
          } else if (
            !Array.isArray(appliedValues[filterName]) &&
            appliedValues[filterName]
          ) {
            return (
              <FlagInfoItem
                key={filterName}
                label={_renderLabel(filterName)}
                variant="outlined"
                onDelete={onDelete}
              />
            )
          }
        })}
    </FlagInfos>
  ) : (
    <></>
  )
}
