import Button from 'components/Button'
import { useFullDialogContext } from 'contexts/FullDialogContext'

export function CloseFullDialog() {
  const { closeDialog } = useFullDialogContext()
  return closeDialog
}

export default function ActionClose() {
  const { closeDialog } = useFullDialogContext()

  return (
    <Button variant="text" onClick={closeDialog}>
      Fechar
    </Button>
  )
}
