import { useMemo } from 'react'
import { Grid, TextField } from '@mui/material'
import { isCPFOrCNPJ } from 'brazilian-values'

import { useFormApi, useFormData } from '@data-c/providers'
import { onlyNumbers } from '@data-c/hooks'

import AutoCompletePessoas from 'components/AutoCompletePessoas'
import FormDrawerContainer from 'components/FormDrawerContainer'
import CnpjCpfTextField from 'components/CnpjCpfTextField'

import { PessoaModel } from 'hooks/queries/usePessoa'
import useNFOutrasInformacoes from 'hooks/queries/useNFOutrasInformacoes'
import useValidations from 'hooks/useValidation'

import * as yup from 'yup'

const schema = yup.object().shape({
  documentoId: yup
    .string()
    .min(11, 'Informe 11 caracteres para CPF ou 14 para CNPJ')
    .nullable()
    .test(
      'CPF/CNPJ-Validator',
      'Informe um CNPJ/CPF válido',
      (documentoId: any) => {
        return documentoId ? isCPFOrCNPJ(documentoId) : true
      },
    ),
})

export default function Form({ nfId }: { nfId: string }) {
  const { setValidationErrors, validationProps } = useValidations()

  const { formValues: data, isOpen } = useFormData<PessoaModel>()
  const { changeValue, toggleSubmit, closeForm, changeValues } =
    useFormApi<PessoaModel>()

  const { useAlterarIntermediador } = useNFOutrasInformacoes()
  const { mutateAsync: alterarIntermediador } = useAlterarIntermediador()

  async function handleSubmit() {
    setValidationErrors(null)
    schema
      .validate(data, { abortEarly: false })
      .then(async () => {
        toggleSubmit(true)
        const { documentoId, nome } = data
        await alterarIntermediador({
          nfId,
          data: {
            intermediadorCNPJ: documentoId,
            intermediadorIdentificador: nome,
          },
        }).finally(() => {
          toggleSubmit(false)
        })
        closeForm()
      })
      .catch((err) => {
        setValidationErrors(err)
      })
      .finally(() => {
        toggleSubmit(false)
      })
  }

  const title = useMemo(() => {
    return `${data?.documentoId ? 'Alterar' : 'Adicionar'} Intermediador`
  }, [isOpen])

  return (
    <FormDrawerContainer title={title} onSubmit={handleSubmit}>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AutoCompletePessoas
          name="id"
          label="Pessoa Intermediador"
          onChange={(_, value) => changeValues(value as PessoaModel)}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <CnpjCpfTextField
          name="documentoId"
          inputProps={{ label: 'CNPJ' }}
          value={data?.documentoId || ''}
          onChange={(e) =>
            changeValue('documentoId', onlyNumbers(e.target.value) || null)
          }
          {...validationProps('documentoId')}
        />
      </Grid>
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <TextField
          name="nome"
          label="Identificador Cadastro"
          value={data?.nome || ''}
          onChange={(e) => changeValue('nome', e.target.value || null)}
        />
      </Grid>
    </FormDrawerContainer>
  )
}
