import { formatToCPFOrCNPJ } from 'brazilian-values'
import { Typography } from '@mui/material'
import { useFormApi } from '@data-c/providers'
import CardInfo, { CardInfoActions } from 'components/CardInfo'

import { PessoaModel } from 'hooks/queries/usePessoa'
import { NFModel } from 'hooks/queries/useNF'

import Form from './components/Form'

interface InteresseDoFiscoProps {
  nf?: NFModel
}

export default function Intermediador({ nf }: InteresseDoFiscoProps) {
  const { openForm } = useFormApi<PessoaModel>()

  function handleOpenForm() {
    const dataCliente = {
      documentoId: nf?.intermediadorCNPJ,
      nome: nf?.intermediadorIdentificador,
    } as PessoaModel
    openForm(dataCliente)
  }

  return (
    <CardInfo subtitle="Intermediador">
      <Typography variant="body2">
        {formatToCPFOrCNPJ(nf?.intermediadorCNPJ || '')}
      </Typography>
      <Typography variant="body2">
        {nf?.intermediadorIdentificador || ''}
      </Typography>

      <CardInfoActions
        showAddButton={!nf?.intermediadorCNPJ}
        onClick={handleOpenForm}
      />
      <Form nfId={nf?.id || ''} />
    </CardInfo>
  )
}
