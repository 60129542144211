import { Typography } from '@mui/material'

interface TitleProps {
  children: string
}

export default function Title({ children }: TitleProps) {
  return (
    <Typography variant="h3" component="h2" fontWeight="bold">
      {children}
    </Typography>
  )
}
