import {
  Check as CheckIcon,
  Clear as ClearIcon,
  DoneAll as DoneAllIcon,
} from '@mui/icons-material'

import { useTagContext } from 'components/Tags'
import ButtonContainer from 'components/ButtonContainer'
import Button from 'components/Button'

import { useFullDialogContext } from 'contexts/FullDialogContext'

export default function Actions() {
  const { closeDialog } = useFullDialogContext()
  const { onSaveTags, onResetTags, onSelectAllTags } = useTagContext()

  function handleSave() {
    onSaveTags()
    closeDialog()
  }

  return (
    <ButtonContainer direction="row" alignItems="center" padding={2}>
      <Button fullWidth startIcon={<DoneAllIcon />} onClick={onSelectAllTags}>
        Todos
      </Button>
      <Button fullWidth startIcon={<ClearIcon />} onClick={onResetTags}>
        Limpar
      </Button>
      <Button
        fullWidth
        variant="contained"
        startIcon={<CheckIcon />}
        onClick={handleSave}
      >
        Salvar
      </Button>
    </ButtonContainer>
  )
}
