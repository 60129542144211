import { Stack } from '@mui/material'
import { useFilterApi, useFilterData } from '@data-c/providers'

import AutoCompleteGrupoProduto from 'components/AutoCompleteGrupoProduto'
import SearchTextField from 'components/SearchTextField'
import FilterContainer from 'components/FilterContainer'

import {
  ProdutoConsultaFilltersOptions,
  ProdutoConsultaFilters,
} from 'hooks/queries/useProduto'
import Tags from 'components/Tags'

export function formatarFiltros(
  filters?: ProdutoConsultaFilters,
): ProdutoConsultaFilters {
  return {
    ...filters,
    produtoGrupoId: filters?.produtoGrupo?.id,
  }
}

export function formatarFilterLabels(filterLabel: string, filterValue: any) {
  switch (filterLabel) {
    case 'Grupo':
      return `${filterLabel}: ${filterValue?.nome || ''}`
    case 'Tags':
      const tagsAmount = (filterValue || []).reduce(
        (acc: number, grupo: any) => acc + grupo?.tags?.length,
        0,
      )
      return `${filterLabel}: ${tagsAmount || ''}`
    default:
      return `${filterLabel}: ${filterValue}`
  }
}

export default function Filter() {
  const { changeFilterValue } = useFilterApi()
  const { filterValues } = useFilterData<
    ProdutoConsultaFilltersOptions,
    ProdutoConsultaFilters
  >()

  return (
    <FilterContainer title="Opções de Filtro" triggerButtonLabel="Filtros">
      <Stack spacing={2}>
        <SearchTextField
          placeholder="Pesquisar produto"
          value={filterValues?.nome?.value || ''}
          onSearch={(query) => changeFilterValue('nome', query)}
        />

        <AutoCompleteGrupoProduto
          name="grupoProdutoId"
          label={filterValues?.produtoGrupo?.label}
          value={filterValues?.produtoGrupo?.value || null}
          onChange={(_, value) => changeFilterValue('produtoGrupo', value)}
        />

        <Tags
          storageId="@persist:stella-dfe-tag-filters-pagina-produtos"
          onChange={(value) => changeFilterValue('tagFiltros', value)}
        />
      </Stack>
    </FilterContainer>
  )
}
