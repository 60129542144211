import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material'

import { AutoCompleteProps } from 'hooks/useAutocomplete'
import usePessoa, { PessoaModel } from 'hooks/queries/usePessoa'

export default function AutoCompletePessoas(
  props: AutoCompleteProps<PessoaModel>,
) {
  const { onChange, value, autoFocus } = props
  const { useQueryObterPessoas } = usePessoa()
  const { data, isLoading } = useQueryObterPessoas({ query: '', ativo: false })

  return (
    <Autocomplete
      value={value}
      disablePortal
      options={data?.data || []}
      onChange={onChange}
      getOptionLabel={(pessoa) => {
        return `${pessoa?.codigo} - ${pessoa?.nome}`
      }}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          value={value}
          label="Pessoas"
          autoFocus={autoFocus}
        />
      )}
      loadingText="Carregando..."
      loading={isLoading}
    />
  )
}
