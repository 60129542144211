import { ErrorInterface, HttpResponseInterface } from '@data-c/hooks'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import apiCadastro from 'services/cadastro-api'

export interface TagGrupoModel {
  id: string
  nome: string
  tags: TagModel[]
}

export interface TagModel {
  id: string
  nome: string
  isChecked?: boolean
}

export async function obterTags() {
  const response = await apiCadastro.post('Tag/Consultar', {})
  return response?.data
}

export function useQueryObterTags() {
  return useQuery<
    HttpResponseInterface<TagGrupoModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['TAGS'], () => obterTags())
}

export default function useTag() {
  return {
    useQueryObterTags,
  }
}
