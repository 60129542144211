import { useMemo } from 'react'

import { useFilterData } from '@data-c/providers'
import { formatCurrency } from '@data-c/hooks'

import ContentContainer from 'components/Container/components/ContentContainer'
import EmptyData from 'components/EmptyData'

import useProduto, {
  ProdutoConsultaFilltersOptions,
  ProdutoConsultaFilters,
} from 'hooks/queries/useProduto'
import useCapitalize from 'hooks/useCapitalize'

import checkFilter from 'utils/checkFilters'

import ProdutoCardInfo from './components/ProdutoCardInfo'
import { formatarFiltros } from '../Filter'

export default function Lista() {
  const { capitalizeFirstLetter } = useCapitalize()
  const { searchId, appliedValues } = useFilterData<
    ProdutoConsultaFilltersOptions,
    ProdutoConsultaFilters
  >()

  const { useQueryObterProdutoConsulta } = useProduto()
  const { data, isLoading, isFetching, error } = useQueryObterProdutoConsulta({
    queryParams: formatarFiltros(appliedValues),
    requestId: searchId,
  })

  const dataFormatada = useMemo(
    () =>
      data?.data?.map((item) => {
        return {
          id: item?.id,
          nome: capitalizeFirstLetter(item?.nome || ''),
          codigo: item?.codigo || '',
          precoUnitario: formatCurrency(item?.precoUnitario || ''),
          estoque: formatCurrency(item?.estoque || ''),
          produtoGrupoNome: item?.produtoGrupoNome || '',
        }
      }) || [],
    [data?.data],
  )

  const showFilters = checkFilter(appliedValues)
  return (
    <ContentContainer
      isLoading={isLoading || isFetching}
      error={error}
      disablePadding
      customSx={{
        margin: `${showFilters ? '168px' : '118px'} 0 8px`,
      }}
    >
      {dataFormatada.length > 0 ? (
        dataFormatada.map((item) => {
          return <ProdutoCardInfo key={item.id} data={item} />
        })
      ) : (
        <EmptyData description="Nenhum registro foi encontrado!" />
      )}
    </ContentContainer>
  )
}
