import { ReactNode } from 'react'
import ButtonContainer from 'components/ButtonContainer'

interface ActionsProps {
  children: ReactNode
}

export default function Actions({ children }: ActionsProps) {
  return (
    <ButtonContainer flexDirection="row" alignItems="center" gap={0}>
      {children}
    </ButtonContainer>
  )
}
