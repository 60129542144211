import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import { apiMapping } from 'hooks/useApi'
import useDialog from 'hooks/useDialog'

import HttpObjResponseInterface from 'interfaces/HttpObjResponseInterface'
import TokenPermissionKeys from 'interfaces/TokenPermissionKeys'

export interface FullDialogData<T> {
  data: T
  isOpen: boolean
  closeDialog: () => void
  openDialog: (data?: T) => void
}

interface RequestProps {
  api?: TokenPermissionKeys
  route?: string
  key?: string
  getById?: (id: string) => Promise<any>
}

interface FullDialogProps {
  requestConfig?: RequestProps
  children: ReactNode
}

export const FullDialogContext = createContext({} as FullDialogData<any>)

export default function FullDialogProvider<T extends { id: string }>(
  props: FullDialogProps,
) {
  const { requestConfig, children } = props

  const [isQueryEnabled, setIsQueryEnabled] = useState(false)
  const { data, isOpen, openDialog, closeDialog, setDialog } = useDialog<T>()

  async function obterPorId(id: string) {
    const {
      api = 'stella-faturamento',
      route = '',
      getById,
    } = requestConfig || {}

    if (getById) {
      return getById(id)
    }

    const response = await apiMapping[api].get(route, {
      params: { id },
    })
    return response?.data
  }

  const { data: _data } = useQuery<HttpObjResponseInterface<any>, AxiosError>(
    [requestConfig?.key, data?.id],
    () => {
      return obterPorId(data?.id || '')
    },
    { enabled: isQueryEnabled },
  )

  useEffect(() => {
    if (
      isOpen &&
      ((props?.requestConfig?.api && props?.requestConfig?.route) ||
        props?.requestConfig?.getById)
    ) {
      setIsQueryEnabled(true)
    }
  }, [isOpen, props?.requestConfig])

  useEffect(() => {
    if (_data?.data) {
      setDialog(_data?.data)
    }
  }, [_data?.data])

  return (
    <FullDialogContext.Provider
      value={{
        data,
        isOpen,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </FullDialogContext.Provider>
  )
}

export function useFullDialogContext<T>() {
  return useContext<FullDialogData<T>>(FullDialogContext)
}
