import ActionClose from './ActionClose'
import Container from './Container'
import Toolbar from './Toolbar'
import Content from './Content'
import Trigger from './Trigger'
import Actions from './Actions'
import Header from './Header'
import Title from './Title'
import Root from './Root'

const FullDialog = {
  ActionClose,
  Container,
  Actions,
  Trigger,
  Toolbar,
  Content,
  Header,
  Title,
  Root,
}

export default FullDialog
