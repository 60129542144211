import { createContext, useContext, useEffect, useReducer } from 'react'

import { Stack, Typography } from '@mui/material'

import FullDialog from 'components/FullDialog'
import Button from 'components/Button'

import useTag from 'hooks/queries/useTag'

import Reducer, { ReducerStateProps } from './reducer'
import ListItens from './components/ListItens'
import Actions from './components/Actions'

export interface TagContextData {
  data: TagsGrupoProps[]
  gruposComTagsSelecionadas: TagsGrupoProps[]
  tagsAmount: number
  selectedTagsAmount: number
  isLoading: boolean
  onChangeTag: (payload: {
    grupoId: string
    tagId: string
    isChecked: boolean
  }) => void
  onSaveTags: () => void
  onResetTags: () => void
  onSelectAllTags: () => void
}

export interface TagsGrupoProps {
  id: string
  nome: string
  tags: TagProps[]
}

interface TagProps {
  id: string
  nome: string
  isChecked?: boolean
}

export enum ActionTypes {
  SET_DATA_INITIAL = 'SET_DATA_INITIAL',
  GET_SAVED_DATA = 'GET_SAVED_DATA',
  CHANGE_TAG = 'CHANGE_TAG',
  SAVE_TAGS = 'SAVE_TAGS',
  RESET_TAGS = 'RESET_TAGS',
  SELECT_ALL_TAGS = 'SELECT_ALL_TAGS',
}

export const TagContext = createContext({} as TagContextData)

const initialState: ReducerStateProps = {
  data: [],
  gruposComTagsSelecionadas: [],
  tagsAmount: 0,
  selectedTagsAmount: 0,
}

interface TagsProps {
  storageId: string
  onChange: (value: TagsGrupoProps[]) => void
}

export default function Tags(props: TagsProps) {
  const { storageId, onChange } = props

  const [state, dispatch] = useReducer(Reducer, initialState)

  const { useQueryObterTags } = useTag()
  const { data: _data, isLoading: isLoadingObterTags } = useQueryObterTags()

  useEffect(() => {
    setInitialData({ initialData: _data?.data || [], storageId })
  }, [_data?.data, storageId])

  function setInitialData(payload: {
    initialData: TagsGrupoProps[]
    storageId: string
  }) {
    dispatch({
      type: ActionTypes.SET_DATA_INITIAL,
      payload: payload,
    })
  }

  function changeTag(payload: {
    grupoId: string
    tagId: string
    isChecked: boolean
  }) {
    dispatch({
      type: ActionTypes.CHANGE_TAG,
      payload: payload,
    })
  }

  function saveTags() {
    dispatch({
      type: ActionTypes.SAVE_TAGS,
      payload: { storageId },
    })
  }

  function resetTags() {
    dispatch({
      type: ActionTypes.RESET_TAGS,
      payload: { isChecked: false },
    })
  }

  function selectAllTags() {
    dispatch({
      type: ActionTypes.SELECT_ALL_TAGS,
      payload: { isChecked: true },
    })
  }

  const { data, gruposComTagsSelecionadas, tagsAmount, selectedTagsAmount } =
    state

  useEffect(() => {
    onChange(gruposComTagsSelecionadas)
  }, [gruposComTagsSelecionadas])

  return (
    <TagContext.Provider
      value={{
        data,
        gruposComTagsSelecionadas,
        tagsAmount,
        selectedTagsAmount,
        isLoading: isLoadingObterTags,
        onChangeTag: changeTag,
        onSaveTags: saveTags,
        onResetTags: resetTags,
        onSelectAllTags: selectAllTags,
      }}
    >
      <FullDialog.Root>
        <FullDialog.Trigger>
          <Stack gap={0.3}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                Tags: <Typography variant="button">{tagsAmount}</Typography>
              </Typography>
              <Typography variant="body2">
                Selecionadas:{' '}
                <Typography variant="button">{selectedTagsAmount}</Typography>
              </Typography>
            </Stack>

            <Button fullWidth sx={{ display: 'flex', alignItems: ' center' }}>
              Selecionar tags
            </Button>
          </Stack>
        </FullDialog.Trigger>
        <FullDialog.Container>
          <FullDialog.Header>
            <FullDialog.Toolbar>
              <FullDialog.Title>Seleção de tags</FullDialog.Title>
              <FullDialog.Actions>
                <FullDialog.ActionClose />
              </FullDialog.Actions>
            </FullDialog.Toolbar>
          </FullDialog.Header>

          <FullDialog.Content padding={0}>
            <ListItens />
          </FullDialog.Content>

          <Actions />
        </FullDialog.Container>
      </FullDialog.Root>
    </TagContext.Provider>
  )
}

export const useTagContext = () => useContext(TagContext)
