import { useFullDialogContext } from 'contexts/FullDialogContext'
import { cloneElement, ReactElement } from 'react'

interface TriggerProps {
  children: ReactElement
}

export default function Trigger({ children }: TriggerProps) {
  const { openDialog } = useFullDialogContext()

  const clonedChildren = cloneElement(children, {
    onClick: () => openDialog(),
  })

  return <>{clonedChildren}</>
}
