import { ErrorInterface, HttpResponseInterface } from '@data-c/hooks'
import { useQuery } from '@tanstack/react-query'
import QueryParamsInterface from 'interfaces/QueryParamsInterface'
import apiCadastro from 'services/cadastro-api'
import { CidadeModel, UFModel } from './useCidade'
import { AxiosError } from 'axios'

export interface PessoaModel {
  id?: string
  codigo?: number | null
  nome: string
  nomeFantasia?: string | null
  logradouro?: string | null
  bairro?: string | null
  cidadeId: string
  cidadeNome?: string | null
  cidadeModel?: CidadeModel | null
  cidadeUF: string
  ufModel?: UFModel | null
  documentoId: string
  numero?: string | null
  complemento?: string | null
  cidadeUFCodigoIBGE?: string | null
  paisId?: string | null
  paisNome?: string | null
  cep?: string | null
  telefone?: string | null
  inscEstadual?: string | null
  inscEstadualId?: number | string | null
  email?: string | null
  ativo?: boolean
  observacao?: string | null
  dataNascimento?: string | null
}

export async function obterPessoas(queryParams: QueryParamsInterface) {
  const response = await apiCadastro.get(
    `Pessoa/Pesquisar/${queryParams?.ativo}/${queryParams?.query}`,
  )
  return response.data
}

export function useQueryObterPessoas(queryParams: QueryParamsInterface) {
  return useQuery<
    HttpResponseInterface<PessoaModel>,
    AxiosError<ErrorInterface, ErrorInterface>
  >(['PESSOAS', queryParams], () => {
    return obterPessoas(queryParams)
  })
}

export default function usePessoa() {
  return {
    useQueryObterPessoas,
  }
}
